<template>
  <div class="chat-container">
    <div class="container" id="home-page">
      <div class="container">
        <div class="row justify-content-center ">
          <!-- BUSCADOR DE CANALES (START) -->
          <div class="col-md-4 col-xl-3 chat ">
            <div class="card mb-sm-3 mb-md-0 contacts_card chat bg-soft-primary">
              <div class="card-header">
                <div class="mt-4">
                  <vs-input shadow color="#7d33ff" type="email" icon-after v-model="searchContact"
                    label-placeholder="Search...">
                    <template #icon>
                      <i class='uil-search'></i>
                    </template>
                  </vs-input>
                </div>
              </div>
              <div class="card-body contacts_body">
                <ul class="contacts">
                  <li v-for="contact in channels" :key="contact.id">
                    <div class="d-flex bd-highlight">
                      <div class="user_info">
                        <span v-text="contact.name" style="cursor: pointer;" @click="setChat(contact)"></span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- BUSCADOR DE CANALES (END) -->

          <!-- WRITE ZONE (START) -->
          <div class="col-md-8 col-xl-6 chat ">
            <div v-if="!chatIsActive">
              <span>Select a chat...</span>
            </div>
            <div class="card chat bg-soft-primary" v-else>
              <!-- CHAT INFO -->
              <div class="card-header">
                <div class="d-flex bd-highlight">
                  <div class="user_info">
                    <span>
                      Messages of: <span style="font-weight: bolder;">{{ channelActive.name }}</span>
                    </span>
                    <p>
                      <span style="font-size: 10px;">{{ channelActive.messages.length }}</span>
                      Messages
                    </p>
                  </div>
                </div>
              </div>
              <!-- CHAT INFO -->

              <div class="card-body msg_card_body" ref="msgCard">
                <div v-if="!channelActive.messages.length">No messages</div>
                <template v-else>
                  <div v-for="msg in channelActive.messages" :key="msg.id">
                    <div class="d-flex flex-column justify-content-start mb-4 message-burble">
                      <div class="msg_info">
                        <span class="rounded-circle user_img_msg" style="font-weight: bolder;">{{ msg.user.id }}: </span>
                        <span>{{ msg.text }}</span>
                      </div>
                      <div>
                        <p style="font-size: 8px;" class="msg_time message-data-time"
                          v-text="getMessageTime(msg.created_at)"></p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <!-- TYPE MESSAGE -->
              <!-- <div class="card-footer bg-dark">
                <div class="input-group">
                  <textarea v-model="message" class="form-control" placeholder="Type your message..." rows="4"
                    style="resize:none"></textarea>
                  <div class="input-group-append">
                    <vs-button warn>
                      <i class="fa fa-location-arrow"></i>
                    </vs-button>
                  </div>
                </div>
              </div> -->
              <!-- TYPE MESSAGE -->

            </div>
          </div>
          <!-- WRITE ZONE (END) -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "ChatComponent",
  props: {
    channels: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  data() {
    return {
      isArchive: true,
      name: "Adonis",
      totalMessages: 10,
      searchContact: "",
      message: "",
      chatIsActive: false,
      channelActive: {},
    }
  },
  methods: {
    getPicture: function (user) {
      return user
    },
    getMessageTime: function (time) {
      const dateObj = new Date(time).toDateString()

      return dateObj
    },
    setChat(channel) {
      this.chatIsActive = true
      this.channelActive = channel;
      this.setScroll0()
    },
    setScroll0() {
      if (!this.$refs.msgCard) return
      this.$refs.msgCard.scroll({
        top: this.$refs.msgCard.scrollHeight,
      })
    }
  },
  mounted() {
    this.setScroll0()
  },
  updated() {
    this.setScroll0()

  },
}
</script>

<style scoped>
.chat {
  height: 550px;
  max-height: 550px;
  width: max-content;
}

.msg_card_body {
  max-height: 500px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.contacts_body {
  overflow-y: scroll;
  padding: 0;
}

.contacts {
  list-style: none;
  padding: 0;
}

.contacts>li {
  margin: 2rem 0;
  background-color: transparent;
  color: inherit;
  padding: 5px 20px;
}

.contacts>li:hover {
  background-color: rgb(0, 0, 0, 0.2);
  color: #fff;
}

.message-burble {
  background-color: rgb(0, 0, 0, 0.2);
  color: #120;
  width: max-content;
  max-width: 550px;
  padding: 6px 20px 0px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
}

.msg_info {
  font-size: 36px;
}

.chat-container {
  overflow: hidden;
}
</style>