<template>
  <div class="card-body">
    <h5>BIENVENIDO {{ this.user ? this.user.userName.toUpperCase() : "" }}!</h5>
    <div class="mt-5">
      <div class="options-container">
        <vs-button class="options-btn wallet-btn" @click="openUrls"
          >URLS</vs-button
        >
        <vs-button
          class="options-btn currencies-btn"
          @click="addCurrency"
          :loading="fetching"
          >MONEDAS</vs-button
        >
        <vs-button class="options-btn games-btn" @click="handleOpen">{{
          $t("sidebar.games.text").toUpperCase()
        }}</vs-button>
      </div>
    </div>
    <UrlsView :item="client" ref="urlsViewComponent" />
    <AddCurrency
      :model="clientData"
      :currencies="currencies"
      ref="addCurrency"
      path="clients" />
    <GameModalMenu
      ref="GameModalMenu"
      :model="client"
      :isAdding="isAdding"
      @openListGame="handleListGame" />
    <ListRoulette
      :item="clientRoulette"
      :roulettes="rouletteProps"
      :client="clientData"
      :userRole="user.rol"
      ref="ListRoulette" />
    <b-modal v-model="edit" size="lg" hide-footer>
      <PageForm :typeform="typeform" @closeModa="closeModa" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UrlsView from "./urlsView.vue";
import AddCurrency from "@/components/AddCurrency.vue";
import GameModalMenu from "@/components/GameModalMenu.vue";
import ListRoulette from "./listRoulette.vue";
import PageForm from "./form.vue";

export default {
  components: { UrlsView, AddCurrency, GameModalMenu, ListRoulette, PageForm },
  data() {
    return {
      currencies: [],
      clientData: {},
      fetching: false,
      isAdding: false,
      clientRoulette: [],
      rouletteProps: [],
      typeform: {},
      edit: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUserData",
      client: "clients/getClient",
      getCurrencies: "currencies/getCurrencies",
      getClientRoulettes: "clients/getClientRoulettes",
    }),
  },
  methods: {
    async addCurrency() {
      this.fetching = true;
      const clientCurrencies = await this.fetchClientCurrencies({
        path: "clients",
        id: this.client._id,
      });

      await this.fetchCurrencies({
        role: this.user.role.name,
        user: this.user,
      });
      this.clientData = { ...this.client, currencies: clientCurrencies };
      this.currencies = this.getCurrencies;
      this.$refs.addCurrency.modal = true;
      this.fetching = false;
    },
    handleOpen() {
      this.clientData = { ...this.clientData, ...this.client };
      this.isAdding = false;
      this.$refs.GameModalMenu.modal = true;
    },
    openUrls() {
      this.$refs.urlsViewComponent.modal = true;
    },
    async handleListGame(data) {
      const { game, model } = data;

      if (this.isAdding) return this.handleAddGame(game, model);

      this.loading = true;
      switch (game.name) {
        case "Roulette": {
          await this.handleListRoulette(model);
          break;
        }
        case "Dragon Tiger":
          {
            await this.handleListDragonTiger(model);
          }
          break;
        case "External Games":
          {
            await this.handleListGames(model);
          }
          break;

        default:
          break;
      }

      if (!this.$refs[game.ref]) return (this.loading = false);
      this.$refs[game.ref].modal = true;
    },
    async handleListRoulette(model) {
      await this.fetchClientRoulettes({ id: model._id });
      const roulettes = this.getClientRoulettes.map((cr) => {
        return {
          ...cr.roulette,
          clientRoulette: cr._id,
        };
      });
      this.clientRoulette = roulettes;
      this.rouletteProps = roulettes;
      this.clientData = model;
      this.loading = false;
    },
    config() {
      this.typeform = { ...this.client };
      this.edit = true;
    },
    closeModa() {
      this.edit = false;
      this.typeform = {};
      this.fetchClient(this.user.client);
    },
    ...mapActions({
      fetchClient: "clients/fetchClient",
      fetchClientCurrencies: "clients/fetchClientCurrencies",
      fetchCurrencies: "currencies/fetchCurrencies",
      fetchClientRoulettes: "clients/fetchClientRoulettes",
    }),
  },
  mounted() {
    this.fetchClient(this.user.client);
  },
};
</script>

<style scoped>
.options-container {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 0.2rem;
}

.options-container > button {
  min-height: 5rem;
}

.options-btn {
  background-repeat: no-repeat;
  background-position: 105% 0.5rem;
  background-size: 5rem;
  font-weight: bolder;
}

.config-btn {
  background-image: url("https://api.iconify.design/uil:setting.svg?color=%23ffffff");
}

.games-btn {
  background-image: url("https://api.iconify.design/uil:game-structure.svg?color=%23ffffff");
}

.currencies-btn {
  background-image: url("https://api.iconify.design/uil:money-bill.svg?color=%23ffffff");
}

.wallet-btn {
  background-image: url("https://api.iconify.design/uil:wallet.svg?color=%23ffffff");
}
</style>
