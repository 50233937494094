<template>
  <b-card-group deck>
    <b-card 
        v-for="(card,i) in items"
        :key="i"
        :header="card.roulette"
        header-tag="header"
        class="w-25"
    >
      <b-card-text><span class="text-primary">Bets $</span> : {{card.totalBet}}</b-card-text>
      <b-card-text><span class="text-primary">Wins $</span> : {{card.totalWin}}</b-card-text>
      <b-card-text><span class="text-primary">Bets</span> : {{card.totalBets}}</b-card-text>
      <b-card-text><span class="text-primary">Profit</span> : {{card.profit}}/0</b-card-text>
      <b-card-text><span class="text-primary">Players/Active</span> : {{card.totalPlayers}}/0</b-card-text>
      <b-card-text><span class="text-primary">RTP</span> : {{card.RTP}}%</b-card-text>
    </b-card>
  </b-card-group>
</template>

<script>
export default{
    name:"MainData",
    props:{
        items:Array
    }
}
</script>